<!-- 登录 -->
<template>
  <div class="login-cont">
    <div class="login-logo">
      <div class="login-logo-img"><img  :src="websiteInfo.pc_logo" /></div>
      <span class="login-logo-text">{{websiteInfo.gongzhonghao}}</span>
    </div>
    <div class="login-form-bg">
      <div class="login-form-cont">
        <div class="login-form-cont-img"><img :src="websiteInfo.pc_logo" /></div>
        <div class="login-form-cont-text">{{websiteInfo.gongzhonghao}}</div>
        <div class="login-form-cont-input-bg">
          <text>用户名</text>
            <input class="login-form-cont-input"  placeholder-class="login-form-cont-placeholder" v-model="username" name="username" placeholder="身份证号/手机号/用户名">
          <div>
             <text>密码</text>
            <input class="login-form-cont-input"  placeholder-class="login-form-cont-placeholder" type="password" v-model="password" name="password" placeholder="登录密码">
          </div>
          <div class="login-form-cont-forgetPwd" @click="forgetPwdBtn">忘记密码</div>
          <div class="login-form-cont-btn" :loading="loading"  @click="login">登录</div>
          <div class="login-form-cont-reg">
            <div>还没账号？</div>
            <div class="login-form-cont-reggo" @click="register">立即注册</div>
          </div>
        </div>
      </div>
    </div>
    <div class="login-form-cont-copyright">{{websiteInfo.copyright}}  | <a href="https://beian.miit.gov.cn/"
        target="_blank">{{websiteInfo.icp}}</a>
    </div>
  </div>

</template>

<script>

    import interfaces from '../../../utils/interface.js'
  export default {
    components: {},
    data() {
      return {
        loading: false,
        username: '',
        password: '',
        websiteInfo: [],
      };
    },
    created() {
      let that = this;
      that.getWebsiteInfo();
    },
    methods: {
      // 配置信息
      getWebsiteInfo() {
        let that = this;
        that.$request.getWebsiteInfo().then((res) => {
          if (res.code == 200) {
            that.websiteInfo = res.datas;
          }
        });
      },
      //登录
      login() {
        let that = this;
        if (!that.username) {
          /* that.$message.success('请......') */
          /* that.$message.warning('请......') */
          /* that.$message.error('请......') */
          /* that.$message.info('请......') */
          /* this.$message({
          	type: 'error',
          	message: '身份证号码/手机号码/用户名不能为空！',
          	showClose: false,//默认的message是不可以手动关闭的，如果需要手动关闭，设置showClose为true，一般与duration搭配使用
          	duration: 3000,//显示时间, 毫秒。设为 0 则不会自动关闭，默认3000ms
          	center: true,//是否居中
          	dangerouslyUseHtmlString: false,//将此属性设置为true，message会被当成html片段处理
          }) */
          /* this.$alert('登录密码不能为空！', '友情提示', {
            confirmButtonText: '确定',
            type: 'warning'
          }) */
          that.$myMessage.warning('身份证号码/手机号码/用户名不能为空！');
          return false;
        }
        if (!that.password) {
          that.$myMessage.warning('登录密码不能为空！');
          return false;
        }

        let account = that.username;
        let pwd = that.password;
        let param = {
          account,
          pwd,
        };
        that.$request.login(param).then((res) => {
          if (res.code == 200) {
            //登录成功
            //记录登录状态
            this.loading = true
            that.$store.commit('changeLoginState', 1);
            sessionStorage.setItem('isLogin', 1);
            sessionStorage.setItem('token', res.datas.token);
            sessionStorage.setItem('identity', res.datas.identity);
            that.$myMessage.success(res.msg);
            that.loading = true
            // that.$router.push({name:'Tuichudenglu_jiaohu'})
            setTimeout(function() {
              that.$router.replace('/')
            }, 300)
          }
        });
      },
      forgetPwdBtn() {
        let that = this;
        that.$router.replace('/forgotPassword');
      },
      register() {
        let that = this;
        that.$router.replace('/register');
      },
    },
  };
</script>

<style scoped lang="css">
.login-cont{
    height: 100vh;
  }
.login-logo{
  padding: 1.2rem 5rem;
  display: flex;
  align-items: center;
}
.login-logo-img{
  margin-right: 1.5rem;
  width: 3rem;
  height: 3rem;
}
.login-logo-img img,.login-form-cont-img img{
 width: 100%;
 height: 100%;
}
.login-logo-text{
  font-size: 1.5rem;
  color: #3D3D3D;
}
.login-form-bg{
  display: flex;
  justify-content: flex-end;
  text-align: center;
  height: calc(100vh - 5.4rem);
  background-image: url('https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/619256350163bf0011e5cbf2/63149b9d014fb40011ef3514//16634982948380011872.png');
  background-repeat: no-repeat;
  background-size: 100%;
}
.login-form-cont{
  width: 30%;
  padding-top: 10%;
  background: rgba(255,255,255,0.8);
}
.login-form-cont-img{
  margin: auto;
  width: 5.8rem;
  height: 5.8rem;
}
.login-form-cont-text{
  margin: 0.5rem 0 2rem;
  font-size: 1.6rem;
}
.login-form-cont-input-bg{
  margin: auto;
  width: 18rem;
  font-size: 0.7rem;
}
.login-form-cont-input{
  margin: 0.5rem 0;
  background-color: rgb(255, 255, 255);
  height: 2.5rem;
  width: 100%;
  outline:none;
  border:none;
  border-radius: 0.2rem;
  text-indent: 4px;
}
.login-form-cont-placeholder{
  background-color: rgb(255, 255, 255);
  color: #858585;
}
.login-form-cont-input:focus {
  outline: none;
  border:none;
  background-color: rgb(255, 255, 255);
}
.login-form-cont-forgetPwd{
  color: #0074FF;
  text-align: right;
  cursor: pointer;
}
.login-form-cont-btn{
  margin: 2rem 0 1rem;
  width: 100%;
  background-color: #0074FF;
  color: #fff;
  line-height: 3.2;
  border-radius: 0.2rem;
  letter-spacing: 4px;
  cursor: pointer;
}
.login-form-cont-reg{
  display: flex;
  justify-content: center;
}
.login-form-cont-reggo{
  color: #0074FF;
  cursor: pointer;
}
.login-form-cont-copyright{
  position: fixed;
  z-index: 1;
  bottom: 2rem;
  width: 100%;
  color: #fff;
  font-size: 0.7rem;
  text-align: center;
}
.login-form-cont-copyright a{
  color: #fff;
  text-decoration: none;
}
</style>
